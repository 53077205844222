import axios from 'axios';
import authHeader from './authHeader';
import Config from './Config';
import { isEmpty } from 'lodash';

const BulkUpload = (data) => {
  console.log('bulk', data);
  const formData = new FormData();
  formData.append('resumes', data);
  return axios.post(
    Config.API_URL + 'api/v1/uploads/bulk-upload/',
    formData,
    {
      headers: authHeader(),
    },
  );
};

const manualUpload = (data) => {
  const formData = new FormData();
  formData.append('resume', data);
  return axios.post(
    Config.API_URL + 'api/v1/uploads/manual-upload/',
    formData,
    {
      headers: authHeader(),
    },
  );
};

//TODO: possibly unused
const getBulkUploadList = (id) => {
  return axios.get(
    `${Config.API_URL}api/v1/candidates/candidates-list/?job_id=${id}&registration_type=BULK_UPLOAD`,
    {
      headers: authHeader(),
    },
  );
};

//TODO: possibly unused
const getManualList = (id) => {
  return axios.get(
    `${Config.API_URL}api/v1/candidates/candidates-list/?job_id=${id}&registration_type=MANUAL_UPLOAD`,
    {
      headers: authHeader(),
    },
  );
};

const getCandidateInfo = (nId) => {
  return axios.get(
    Config.API_URL + 'api/v1/candidates/candidate-details/' + nId + '/',
    {
      headers: authHeader(),
    },
  );
};

const getCandidateResume = (nId) => {
  return axios.get(
    Config.API_URL + `api/v1/jobs/preview-masked-cv/?candidate_id=${nId}`,
    {
      headers: authHeader(),
    },
  );
};

const getSearchData = (searchType, searchValue) => {
  return axios.get(
    Config.API_URL +
      'api/v1/uploads/bulk/list/?' +
      searchType +
      '=' +
      searchValue,
    {
      headers: authHeader(),
    },
  );
};

const getManualSearchData = (searchType, searchValue) => {
  return axios.get(
    Config.API_URL +
      'api/v1/uploads/manual/list/?' +
      searchType +
      '=' +
      searchValue,
    {
      headers: authHeader(),
    },
  );
};

const getExcelSearchData = (searchType, searchValue) => {
  return axios.get(
    Config.API_URL +
      'api/v1/uploads/excel/list/?' +
      searchType +
      '=' +
      searchValue,
    {
      headers: authHeader(),
    },
  );
};

const getCandidateSearchData = (searchType, searchValue) => {
  return axios.get(
    Config.API_URL +
      'api/v1/uploads/candidates/list/?' +
      searchType +
      '=' +
      searchValue,
    {
      headers: authHeader(),
    },
  );
};

//TODO: possibly unused
const getCandiadteList = (id) => {
  return axios.get(
    `${Config.API_URL}api/v1/candidates/candidates-list/?job_id=${id}`,
    {
      headers: authHeader(),
    },
  );
};

const getJobList = () => {
  return axios.get(
    Config.API_URL + 'api/v1/jobs/job-listing/?status=ACTIVE',
    {
      headers: authHeader(),
    },
  );
};

const excelUpload = (data) => {
  const formData = new FormData();
  formData.append('excel', data);
  return axios.post(
    Config.API_URL + 'api/v1/uploads/spreadsheet-upload/',
    formData,
    {
      headers: authHeader(),
    },
  );
};

//TODO: possibly unused
const getExcelUpload = (id) => {
  return axios.get(
    `${Config.API_URL}api/v1/candidates/candidates-list/?job_id=${id}&registration_type=EXCEL_UPLOAD`,
    {
      headers: authHeader(),
    },
  );
};

const addCandidateExperience = (data) => {
  return axios.post(
    Config.API_URL +
      'api/v1/candidates/candidate-experience-by-company-user/',
    data,
    {
      headers: authHeader(),
    },
  );
};

const editCandidateExperience = (data) => {
  const { body, experienceId } = data;
  return axios.put(
    Config.API_URL +
      `api/v1/candidates/candidate-experience-by-company-user/${experienceId}/`,
    body,
    {
      headers: authHeader(),
    },
  );
};

const getNationalities = (params) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.get(
    `${Config.API_URL}api/v1/settings/nationalities/`,
    config,
  );
};

const getKnownLanguages = (params) => {
  const config = {
    params,
    headers: authHeader(),
  };

  return axios.get(`${Config.API_URL}api/v1/settings/languages/`, config);
};

const deleteCandidateExperience = (variables) => {
  const { data, experienceId } = variables;
  const config = {
    headers: authHeader(),
    data,
  };
  return axios.delete(
    Config.API_URL +
      `api/v1/candidates/candidate-experience-by-company-user/${experienceId}/`,
    config,
  );
};

const addCandidateProject = (data) => {
  return axios.post(
    Config.API_URL +
      'api/v1/candidates/candidate-project-by-company-user/',
    data,
    {
      headers: authHeader(),
    },
  );
};

const getConsultancyCandidateList = (params) => {
  const config = {
    params,
    headers: authHeader(),
  };

  return axios.get(
    `${Config.API_URL}api/v1/jobs/consultancy-candidates/`,
    config,
  );
};

const editCandidateProject = (data) => {
  const { body, projectId } = data;
  return axios.put(
    Config.API_URL +
      `api/v1/candidates/candidate-project-by-company-user/${projectId}/`,
    body,
    {
      headers: authHeader(),
    },
  );
};

const deleteCandidateProject = (variables) => {
  const { data, projectId } = variables;
  const config = {
    headers: authHeader(),
    data,
  };
  return axios.delete(
    Config.API_URL +
      `api/v1/candidates/candidate-project-by-company-user/${projectId}/`,
    config,
  );
};

const applyForPreferredJob = (body) => {
  return axios.post(
    Config.API_URL + `api/v1/jobs/apply-candidate-for-job/`,
    body,
    {
      headers: authHeader(),
    },
  );
};

const postExcelData = (data, jobId, params) => {
  const config = {
    headers: authHeader(),
  };
  if (jobId) {
    config['params'] = {
      job_id: jobId,
      ...(!isEmpty(params) ? params : {}),
    };
  }

  return axios.put(
    Config.API_URL + 'api/v1/uploads/spreadsheet-upload/',
    data,
    config,
  );
};

const sendToPreScreening = (data, params) => {
  const config = {
    headers: authHeader(),
  };
  if (!isEmpty(params)) {
    config['params'] = params;
  }

  return axios.post(
    Config.API_URL +
      'api/v1/candidates/candidate-prescreen-invite-and-account-creation/',
    data,
    config,
  );
};

const updateCandidate = (data, candidateId) => {
  return axios.put(
    Config.API_URL +
      'api/v1/candidates/candidate-details/' +
      candidateId +
      '/',
    data,
    {
      headers: authHeader(),
    },
  );
};

const patchCandidate = (data, candidateId) => {
  return axios.patch(
    Config.API_URL +
      'api/v1/candidates/candidate-details/' +
      candidateId +
      '/',
    data,
    {
      headers: authHeader(),
    },
  );
};

const updateCandidateDetailsFetchedFromResume = (data, candidateId) => {
  return axios.put(
    Config.API_URL +
      'api/v1/candidates/invalid-candidate-update/?candidate_details_id=' +
      candidateId,
    data,
    {
      headers: authHeader(),
    },
  );
};

const createCandidate = (data, params) => {
  const config = {
    headers: authHeader(),
  };
  if (!isEmpty(params)) {
    config['params'] = params;
  }
  return axios.post(
    Config.API_URL + 'api/v1/candidates/candidate-manual-upload/',
    data,
    config,
  );
};

const workingConditionData = () => {
  return axios.get(
    Config.API_URL + 'api/v1/uploads/working-conditions/',

    {
      headers: authHeader(),
    },
  );
};

//!-------------------InterviewSchedule------------!---->>
const getInterviewSchedule = (data) => {
  return axios.get(
    `${Config.API_URL}api/v1/interview/requisites/list/${data.id}/`,
    {
      headers: authHeader(),
    },
  );
};
const scheduleInterview = (data) => {
  return axios.post(Config.API_URL + 'api/v1/interview/schedule/', data, {
    headers: authHeader(),
  });
};

const getCandidateResumeScore = (data) => {
  return axios.post(Config.API_URL + 'api/v1/uploads/score/', data, {
    headers: authHeader(),
  });
};

const getSkillsList = (data) => {
  return axios.get(`${Config.API_URL}api/v1/settings/master-skills/`, {
    params: {
      search: data.searchValue,
    },
    headers: authHeader(),
  });
};
const getCertificatesList = (data) => {
  return axios.get(
    `${Config.API_URL}api/v1/settings/master-certifications/?search=${data.searchValue}`,
    {
      headers: authHeader(),
    },
  );
};
const getQualificationsList = (data) => {
  return axios.get(
    `${Config.API_URL}api/v1/settings/master-educations/?search=${data.searchValue}`,
    {
      headers: authHeader(),
    },
  );
};
//TODO: possibly unused
//!----------------getAllCandidateList---------------!---->>
const getAllCandidateList = () => {
  return axios.get(`${Config.API_URL}api/v1/candidates/candidates-list/`, {
    headers: authHeader(),
  });
};
//TODO: possibly unused
//!----------------getAllCandidateListByJobId---------------!---->>
const getAllCandidateListByJobId = (id) => {
  return axios.get(
    `${Config.API_URL}api/v1/candidates/candidates-list/?job_id=${id}`,
    {
      headers: authHeader(),
    },
  );
};
//!-----------------ResumeUpload--------------------!---->>
const resumeUpload = (data, params) => {
  const config = {
    params,
    headers: authHeader(),
  };

  return axios.post(
    Config.API_URL + 'api/v1/uploads/resume-upload/',
    data,
    config,
  );
};
//!-----------------AddToJob------------------------!---->>
const addToJob = (data) => {
  return axios.post(
    Config.API_URL + 'api/v1/jobs/multi-candidate-job-apply/',
    data,
    {
      headers: authHeader(),
    },
  );
};

//!------------------GetJobListOnSearch--------------!----->>
const getJobListBySearch = (data) => {
  return axios.get(
    `${Config.API_URL}api/v1/jobs/job-listing/?status=ACTIVE&search=${data.searchValue}`,
    {
      headers: authHeader(),
    },
  );
};
//!----------------getAllCandidateListByJobId---------------!---->>
const getAllCandidateListAppliedByJobId = (id) => {
  return axios.get(
    `${Config.API_URL}api/v1/candidates/job-candidates-list/?job_id=${id}`,
    {
      headers: authHeader(),
    },
  );
};
//TODO: possibly unused
//!------------------GetCandidateListOnSearch--------------!----->>
const getCandidateListSearch = (data) => {
  return axios.get(
    `${Config.API_URL}api/v1/candidates/candidates-list/?search=${data.searchValue}`,
    {
      headers: authHeader(),
    },
  );
};

/* 
  Api for rejecting a candidate
*/
const rejectCandidate = (data) => {
  return axios.post(Config.API_URL + 'api/v1/candidates/reject/', data, {
    headers: authHeader(),
  });
};

/* 
  Adds a new import source to the sources list
*/
const addImportSource = (data) => {
  const config = {
    headers: authHeader(),
  };
  return axios.post(
    Config.API_URL + 'api/v1/candidates/candidate-import-sources/',
    data,
    config,
  );
};

const setConsultancyCandidateSchedule = (body, params) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.post(
    Config.API_URL + 'api/v1/jobs/schedule-consultancy-candidate/',
    body,
    config,
  );
};

/* 
  Api for fetching import sources
*/
const getImportSources = () => {
  const config = {
    headers: authHeader(),
  };
  return axios.get(
    `${Config.API_URL}api/v1/candidates/candidate-import-sources/`,
    config,
  );
};

/* 
  Gets all resumes based on chosen candidate
*/
const getAllCandidatesResumes = (data) => {
  return axios.post(Config.API_URL + 'api/v1/candidates/compare/', data, {
    headers: authHeader(),
  });
};

/* 
  Deletes other resumes and keeps selected resume
*/

const chooseCandidateResume = (data) => {
  return axios.put(Config.API_URL + 'api/v1/candidates/compare/', data, {
    headers: authHeader(),
  });
};

/* 
  Api for fetching candidates list
*/
const getCandidatesList = (params, columnFilter) => {
  var filter = columnFilter ? columnFilter : '';
  console.log(filter);
  return axios.get(
    `${Config.API_URL}api/v1/candidates/candidates-list/?${filter}`,
    {
      params,
      headers: authHeader(),
    },
  );
};

/* 
  For fetching the list content of the Candidate page's Tabs: Offered, Rejected, Pipeline
*/
const getCandidatePipelineList = (params, filterValue) => {
  const config = {
    params,
    headers: authHeader(),
  };
  var filter = filterValue ? filterValue : '';
  return axios.get(
    `${Config.API_URL}api/v1/candidates/candidates-pipeline/?${filter}`,
    config,
  );
};
/* 
  Get candidates Applied jobs
*/
const getCandidateAppliedJobs = (params) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.get(
    `${Config.API_URL}api/v1/jobs/candidate-job-list/`,
    config,
  );
};

const getDocuments = (params) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.get(
    `${Config.API_URL}api/v1/candidates/candidate-documents/`,
    config,
  );
};

const uploadDocument = (body, params) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.post(
    `${Config.API_URL}api/v1/candidates/candidate-documents/`,
    body,
    config,
  );
};

const confirmDocument = (body, params) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.put(
    `${Config.API_URL}api/v1/candidates/my-profile-document-status/`,
    body,
    config,
  );
};

const getDocumentTypes = () => {
  const config = {
    headers: authHeader(),
  };
  return axios.get(
    `${Config.API_URL}api/v1/company/company-documents-types/`,
    config,
  );
};

const deleteDocument = (id, params) => {
  const config = {
    params,
    headers: authHeader(),
  };

  return axios.delete(
    `${Config.API_URL}api/v1/candidates/candidate-documents/${id}/`,
    config,
  );
};

const sendCandidateInvitationByMail = (body) => {
  const config = {
    headers: authHeader(),
  };
  return axios.post(
    `${Config.API_URL}api/v1/candidates/invite-candidate/`,
    body,
    config,
  );
};

/* 
  Get candidates jobs that are preferred
*/
const getPreferredCandidateJobs = (params) => {
  return axios.get(
    `${Config.API_URL}api/v1/jobs/candidate-preferred-job/`,
    {
      params,
      headers: authHeader(),
    },
  );
};

/* 
  Get the chart values to visualize the Sourcing Statistics Report
*/
const getSourcingReport = (params) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.get(
    `${Config.API_URL}api/v1/reports/candidate-sourcing-report/`,
    config,
  );
};

// Get the chart values to visualize the Sourcing grid Report
const getSourcingReportGrid = (params) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.get(
    `${Config.API_URL}api/v1/reports/candidates-sourcing-report-grid/`,
    config,
  );
};

/* 
  Get the chart values to visualize the Screening Report
*/
const getScreeningReport = (params) => {
  return axios.get(
    `${Config.API_URL}api/v1/reports/candidate-screening-report/`,
    {
      params,
      headers: authHeader(),
    },
  );
};

// Get the chart values to visualize the Screening grid Report
const getScreeningGridReport = (params) => {
  return axios.get(
    `${Config.API_URL}api/v1/reports/candidates-screening-report-grid/`,
    {
      params,
      headers: authHeader(),
    },
  );
};

// Get the chart values to visualize the Sourcing grid Report export
const getSourcingGridReportExport = (params) => {
  return axios.get(
    `${Config.API_URL}api/v1/reports/candidates-sourcing-report-export/`,
    {
      params,
      headers: authHeader(),
    },
  );
};

/* 
  Get the chart values to visualize the Source Pipeline Statistics Report
*/
const getSourcePipelineReport = (params) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.get(
    `${Config.API_URL}api/v1/reports/candidate-sourcing-pipeline-report/`,
    config,
  );
};

/* 
  Get the chart values to visualize the Source Average Hiring Time Report
*/
const getSourceAverageHiringTimeReport = (params) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.get(
    `${Config.API_URL}api/v1/reports/avg-hiring-time-vs-source-report/`,
    config,
  );
};

/* 
  Api for holding a candidate
*/
const holdCandidate = (data) => {
  return axios.post(Config.API_URL + 'api/v1/jobs/hold-candidate/', data, {
    headers: authHeader(),
  });
};

const getScheduleCount = (type) => {
  return axios.get(
    `${Config.API_URL}api/v1/jobs/check-subscription-remaining-credit-limit/${type}/`,
    {
      headers: authHeader(),
    },
  );
};

// candidate video update

const candidateVideoUpload = (file, params) => {
  console.log('params, file', params, file);
  const formData = new FormData();
  formData.append('profile_video', file);
  return axios.put(
    `${Config.API_URL}api/v1/candidates/my-profile-video/${params.candidateId}/`,
    formData,
    {
      headers: authHeader(),
    },
  );
};

// delete candidate video

const deleteCandidateVideoUpload = (params) => {
  console.log('params, file', params);
  return axios.delete(
    `${Config.API_URL}api/v1/candidates/my-profile-video/${params.candidateId}/`,
    {
      headers: authHeader(),
    },
  );
};

// no show list api
const getNoShowList = (params, filterValue) => {
  const config = {
    params,
    headers: authHeader(),
  };
  var filter = filterValue ? filterValue : '';
  return axios.get(
    `${Config.API_URL}api/v1/candidates/list-nowshow-candidates/?${filter}`,
    config,
  );
};

const downloadCandidateResumesByMail = (body) => {
  const config = {
    headers: authHeader(),
  };
  return axios.post(
    `${Config.API_URL}api/v1/candidates/download-resumes/`,
    body,
    config,
  );
};
const candidateService = {
  BulkUpload,
  getBulkUploadList,
  getManualList,
  manualUpload,
  getCandidateInfo,
  getSearchData,
  getJobList,
  getManualSearchData,
  getCandidateSearchData,
  getCandiadteList,
  sendCandidateInvitationByMail,
  addImportSource,
  getImportSources,
  setConsultancyCandidateSchedule,
  excelUpload,
  getExcelUpload,
  getNationalities,
  addCandidateProject,
  editCandidateProject,
  deleteCandidateProject,
  addCandidateExperience,
  editCandidateExperience,
  deleteCandidateExperience,
  postExcelData,
  sendToPreScreening,
  updateCandidate,
  workingConditionData,
  createCandidate,
  getExcelSearchData,
  getInterviewSchedule,
  scheduleInterview,
  getCandidateResumeScore,
  getKnownLanguages,
  getSkillsList,
  getCertificatesList,
  getQualificationsList,
  getAllCandidateList,
  resumeUpload,
  getConsultancyCandidateList,
  getAllCandidateListByJobId,
  addToJob,
  deleteDocument,
  confirmDocument,
  uploadDocument,
  getDocuments,
  getJobListBySearch,
  getSourcePipelineReport,
  getDocumentTypes,
  getSourceAverageHiringTimeReport,
  getAllCandidateListAppliedByJobId,
  getCandidateListSearch,
  rejectCandidate,
  getAllCandidatesResumes,
  chooseCandidateResume,
  getCandidatesList,
  getCandidatePipelineList,
  getCandidateAppliedJobs,
  getPreferredCandidateJobs,
  holdCandidate,
  getSourcingReport,
  getScreeningReport,
  updateCandidateDetailsFetchedFromResume,
  getScheduleCount,
  candidateVideoUpload,
  deleteCandidateVideoUpload,
  applyForPreferredJob,
  getCandidateResume,
  getSourcingReportGrid,
  getScreeningGridReport,
  getSourcingGridReportExport,
  getNoShowList,
  patchCandidate,
  downloadCandidateResumesByMail,
};
export default candidateService;
